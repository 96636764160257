import {
  getBarsRerenderForDesirabilityHistogram,
  HistogramData,
} from '@discngine/moosa-histogram';
import {
  DesirabilityFunctionSingleParams,
  DesirabilityFunctionType,
  FieldType,
  IColumnMetaInfo,
  IScoringFuncProperty,
  SCORING_FUNCTIONS_RULES,
  SortFunctionTableType,
} from '@discngine/moosa-models';
import { InfinityListProps } from '@discngine/moosa-shared-components';
import { DesirabilityFunction } from '@discngine/moosa-shared-components';
import debounce from 'lodash/debounce';
import round from 'lodash/round';
import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

interface DesirabilityFunctionHistogramProps {
  metaData: IColumnMetaInfo;
  desirability: IScoringFuncProperty;
  desirabilityFunctionListProps?: Partial<InfinityListProps>;
  histogramData?: HistogramData | null;
  onParamsChange: (desirability: IScoringFuncProperty) => void;
  onChange: (desirability: IScoringFuncProperty) => void;
  onSave: (desirability: IScoringFuncProperty, existingId?: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onSearch: (query: string, isDiscreteStringFunction: boolean) => void;
}

export const DesirabilityFunctionHistogram = ({
  metaData,
  desirability,
  desirabilityFunctionListProps,
  histogramData,
  onParamsChange,
  onChange,
  onSave,
  onDelete,
  onSearch,
}: DesirabilityFunctionHistogramProps) => {
  const debouncedUpdate = useMemo(() => {
    return debounce((updated) => onChange(updated), 300);
  }, [onChange]);

  const onAddFunctionPoint = useCallback(
    (args: {
      valueX: string | number;
      valueY: number;
      xSort?: SortFunctionTableType | undefined;
      ySort?: SortFunctionTableType | undefined;
      originalPointIndex?: number | undefined;
      fieldType?: FieldType | undefined;
    }) => {
      const { valueX, valueY, xSort, ySort, originalPointIndex, fieldType } = args;

      if (!desirability || !metaData) return;

      const updated = structuredClone(desirability);

      const point = {
        originalPointIndex,
        x: valueX,
        y: valueY,
        id: uuid(),
      };
      const params = updated.functionParams[updated.type] as any;
      const { addPoint } =
        SCORING_FUNCTIONS_RULES[updated.type as DesirabilityFunctionType];

      if (!addPoint) return;

      updated.functionParams[updated.type] = addPoint(
        params,
        point,
        fieldType || metaData.type,
        xSort ?? SortFunctionTableType.None,
        ySort ?? SortFunctionTableType.None
      ) as any;

      onParamsChange(updated);
      debouncedUpdate(updated);
    },
    [debouncedUpdate, desirability, metaData, onParamsChange]
  );

  const onRemoveFunctionPoint = useCallback(
    (index: number) => {
      if (!desirability) return;

      const updated = structuredClone(desirability);

      const params = updated.functionParams[updated.type] as any;
      const { removePoint } =
        SCORING_FUNCTIONS_RULES[updated.type as DesirabilityFunctionType];

      if (!removePoint) return;

      updated.functionParams[updated.type] = removePoint(params, index) as any;

      onParamsChange(updated);
      debouncedUpdate(updated);
    },
    [debouncedUpdate, desirability, onParamsChange]
  );

  const onDiscretePointsOrderChange = useCallback(
    (columnName: string, fromPoint: string | number, toPoint: string | number) => {
      // TODO:
    },
    []
  );

  const onChangeScoringFunction = useCallback(
    (desirability: IScoringFuncProperty) => {
      onParamsChange(desirability);
      debouncedUpdate(desirability);
    },
    [debouncedUpdate, onParamsChange]
  );

  const onFunctionParamsChange = useCallback(
    (params: DesirabilityFunctionSingleParams) => {
      if (!desirability) return;

      const updated = structuredClone(desirability);

      updated.functionParams[updated.type] = params as any; // TODO: try to remove type casting

      onParamsChange(updated);
      debouncedUpdate(updated);
    },
    [debouncedUpdate, desirability, onParamsChange]
  );

  const onMoveFinish = useCallback(() => {
    // TODO: Problem with desirability state between onMoveFinish and onParamsChange
  }, []);

  const onUpdateFunctionPoint = useCallback(
    (x: number, y: number, idx: number, id: string) => {
      if (!desirability) return;

      const updated = structuredClone(desirability);

      updated.functionParams[updated.type] = SCORING_FUNCTIONS_RULES[
        updated.type as DesirabilityFunctionType
      ].movePoint(updated.functionParams[updated.type] as any, {
        originalPointIndex: idx,
        x: x,
        y: round(y, 3),
        id: id,
      }) as any;

      onParamsChange(updated);
      debouncedUpdate(updated);
    },
    [debouncedUpdate, desirability, onParamsChange]
  );

  const handleSave = useCallback(
    (desirability: IScoringFuncProperty, existingId?: string) => {
      return onSave(desirability, existingId);
    },
    [onSave]
  );

  const barsRerender = useMemo(() => {
    return getBarsRerenderForDesirabilityHistogram(desirability.type, histogramData);
  }, [desirability.type, histogramData]);

  return (
    <DesirabilityFunction
      barsRerender={barsRerender}
      desirability={desirability}
      infinityListProps={{
        data: [],
        hasMore: false,
        isLoading: false,
        loadNext: () => {},
        revalidate: () => new Promise(() => {}),
        ...desirabilityFunctionListProps,
      }}
      metadata={metaData}
      onAddFunctionPoint={onAddFunctionPoint}
      onChangeScoringFunction={onChangeScoringFunction}
      onDelete={onDelete}
      onDiscretePointsOrderChange={onDiscretePointsOrderChange}
      onMoveFinish={onMoveFinish}
      onParamsChange={onFunctionParamsChange}
      onRemoveFunctionPoint={onRemoveFunctionPoint}
      onSave={handleSave}
      onSearch={(query) => onSearch(query, !!desirability.isDiscreteStringFunction)}
      onUpdateFunctionPoint={onUpdateFunctionPoint}
    />
  );
};
