import {
  BarsRerender,
  DateHistogram,
  DateHistogramData,
  DiscreteBar,
  DiscreteHistogram,
  DiscreteHistogramData,
  GroupColor,
  HistogramData,
  HistogramView,
  isDiscreteHistogramData,
  isNumericHistogramData,
  NumericBar,
  NumericHistogram,
  NumericHistogramData,
  toPrecisionString,
} from '@discngine/moosa-histogram';
import {
  ColumnId,
  Condition,
  ConditionType,
  DateCondition,
  DecisionTreeArrow,
  DecisionTreeChartNode,
  DecisionTreeDateNode,
  DecisionTreeNode,
  DecisionTreeNodeGroups,
  DecisionTreePropertyNode,
  DecisionTreeStructSearchNode,
  DiscreteValue,
  DTArrowId,
  DTGroupOption,
  DTNodeId,
  DTNodeType,
  FieldType,
  IColumnLabelMap,
  IColumnMetaInfo,
  IDecisionTreeNew,
  isAndNode,
  isAndNodeGroups,
  isAtLeastNNode,
  isAtMostXNode,
  isChartNode,
  isDateNode,
  isGroupNode,
  isOrNode,
  isOrNodeGroups,
  isPropertyLikeNode,
  isPropertyNode,
  isPropertyNodeGroups,
  isStructSearchNode,
  PortGroupingType,
} from '@discngine/moosa-models';
import { DecisionTreeCellValuesWithGroups } from 'calculateResults/types';
import {
  getDefaultConditionSettings,
  getDefaultDateConditions,
} from 'components/PropertyNodeEditPanel/utils';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import go from 'gojs';
import { HISTOGRAM_BAR } from 'lib/go/colors';
import { updateModelData } from 'lib/go/utils';
import { renderToString } from 'react-dom/server';
import { v4 as uuid } from 'uuid';

import { HISTOGRAM_HEIGHT, HISTOGRAM_WIDTH, NODE_WIDTH, NODES_GAP } from './constants';
import {
  DiagramArrowData,
  DiagramArrowPort,
  DiagramData,
  DiagramNodeData,
  DiagramNodePort,
  DiagramState,
  DTNodeOutputPortType,
  NodeConditionMismatch,
  NodeConditionMismatchLevel,
  NodeConditionMismatchType,
  XYCoord,
} from './types';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export function assertUnreachable(x: never, msg = "Didn't expect to get here"): never {
  throw new Error(msg);
}

export const getColumnLabel = (
  columnId: string,
  columnLabelMap: IColumnLabelMap | undefined
): string => {
  return columnLabelMap?.[columnId]?.label ?? columnId;
};

/**
 * Common date parsing function for DT and histogram operations.
 *
 * IMPORTANT: All dates should be parsed as UTC dates without timezone to be compatible with backend representation
 *
 * @param timestamp Date string or Unix time
 * @param format Dayjs format string
 */
export const parseUtcDatetime = (timestamp: number | string, format?: string): Dayjs => {
  return dayjs.utc(timestamp, format);
};

const parseUtcDate = (cellValue: string | number, format?: string): Dayjs | null => {
  const date = parseUtcDatetime(cellValue, format);

  return date.isValid() ? date : null;
};

/**
 *
 * @param cellValue dataset cell value
 * @param format - dayjs parsing format https://day.js.org/docs/en/parse/string-format
 * @returns - Dayjs or null for missing and invalid values (invalid value should be considered as missing)
 */
export const cellValueToDate = (cellValue: unknown, format?: string): Dayjs | null => {
  if (typeof cellValue !== 'string' && typeof cellValue !== 'number') {
    return null;
  }

  const re = /T\d\d:\d\d:\d\d([+-]\d\d:\d\d)?$/;

  if (typeof cellValue === 'string' && re.test(cellValue)) {
    // Switch timezone to UTC 00:00:00
    return parseUtcDate(cellValue.replace(re, ''), format);
  } else {
    return parseUtcDate(cellValue, format);
  }
};

/**
 * Converts a condition object to its string representation with specified precision.
 *
 * @param condition - The condition object to stringify.
 * @param precision - The number of decimal places to include in numeric values.
 * @returns The string representation of the condition.
 */
export const stringifyCondition = (condition: Condition, precision: number): string => {
  switch (condition.type) {
    case ConditionType.DesirabilityFunction: {
      const threshold = toPrecisionString(condition.threshold, precision);

      return `SCORE ${condition.operation} ${threshold}`;
    }

    case ConditionType.Simple: {
      const threshold = toPrecisionString(condition.threshold, precision);

      return `VALUE ${condition.operation} ${threshold}`;
    }

    case ConditionType.Range: {
      const min = toPrecisionString(condition.min.threshold, precision);
      const max = toPrecisionString(condition.max.threshold, precision);

      return `${min} ${condition.min.operation} VALUE ${condition.max.operation} ${max}`;
    }

    case ConditionType.Discrete: {
      return `VALUE ∈ ${
        condition.values
          .filter((discreteValue) => discreteValue.isSelected)
          .map((discreteValue) => discreteValue.value)
          .join(', ') || '∅'
      }`;
    }

    default:
      assertUnreachable(condition);
  }
};

/**
 * Retrieves the base64 representation of a SVG Histogram
 *
 * @param node - The decision tree node.
 * @param metaData - The metaData for given node.
 * @param barsRerender - Function for drawing bars in a decision tree.
 * @returns The base64-encoded SVG representation.
 */
export const getDesirabilityHistogram = (
  node: DecisionTreePropertyNode,
  metaData: IColumnMetaInfo,
  barsRerender?: BarsRerender
): string | null => {
  if (node.condition?.type !== ConditionType.DesirabilityFunction) {
    return null;
  }

  const svg = renderToString(
    <HistogramView
      barsRerender={barsRerender}
      desirability={node.condition.desirability}
      height={HISTOGRAM_HEIGHT}
      isStatic={true}
      metadata={metaData}
      width={HISTOGRAM_WIDTH}
      onAddFunctionPoint={() => {}}
      onMoveFinish={() => {}}
      onRemoveFunctionPoint={() => {}}
      onUpdateFunctionPoint={() => {}}
    />
  );

  return `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(svg)))}`;
};

/**
 * Retrieves the base64 representation of a SVG Histogram
 *
 * @param node - The decision tree node.
 * @param data - The data about histogram bars.
 * @returns The base64-encoded SVG representation.
 */
export const getNodeHistogram = (
  node: DecisionTreePropertyNode | DecisionTreeDateNode,
  data: HistogramData | null
): string | null => {
  if (!node.condition || !data) {
    return null;
  }

  let svg: string | null = null;

  switch (node.condition.type) {
    case ConditionType.Discrete: {
      if (!isDiscreteHistogramData(data)) {
        return '';
      }

      svg = renderToString(
        <DiscreteHistogram
          condition={node.condition}
          data={data}
          height={HISTOGRAM_HEIGHT}
          width={HISTOGRAM_WIDTH}
        />
      );
      break;
    }

    case ConditionType.Simple:
    case ConditionType.Range:
      switch (data.type) {
        case 'numeric':
          svg = renderToString(
            <NumericHistogram
              condition={node.condition}
              data={data}
              height={HISTOGRAM_HEIGHT}
              width={HISTOGRAM_WIDTH}
            />
          );
          break;

        case 'date':
          svg = renderToString(
            <DateHistogram
              condition={node.condition}
              data={data}
              height={HISTOGRAM_HEIGHT}
              width={HISTOGRAM_WIDTH}
            />
          );
          break;

        default:
          return '';
      }

      break;

    default:
      console.info('Condition type is not supported');
  }

  if (!svg) return null;

  // TODO: replace deprecated unescape function
  return `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(svg)))}`;
};

/**
 * Retrieves the base64 representation of SVG Histogram for chart node
 *
 * @param node - The decision tree node.
 * @param data - The histogram data for given node.
 * @returns The base64-encoded SVG representation.
 */
export const getChartNodeHistogram = (
  node: DecisionTreeChartNode,
  data: HistogramData | null
): string | null => {
  if (node.columnId === null) {
    return null;
  }

  const numericHistogramData = data && isNumericHistogramData(data) ? data : null;
  const discreteHistogramData = data && isDiscreteHistogramData(data) ? data : null;

  let svg: string | null = null;

  if (discreteHistogramData) {
    svg = renderToString(
      <DiscreteHistogram
        data={discreteHistogramData}
        height={HISTOGRAM_HEIGHT}
        width={HISTOGRAM_WIDTH}
      />
    );
  } else if (numericHistogramData) {
    svg = renderToString(
      <NumericHistogram
        data={numericHistogramData}
        height={HISTOGRAM_HEIGHT}
        width={HISTOGRAM_WIDTH}
      />
    );
  }

  if (!svg) return null;

  // TODO: replace deprecated unescape function
  return `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(svg)))}`;
};

const getPropertyNodeTypeMismatch = (
  node: DecisionTreePropertyNode,
  metaData: IColumnMetaInfo | undefined
): NodeConditionMismatch | null => {
  if (!node.condition) {
    return null;
  }

  if (!metaData) {
    // Column is missing
    return {
      level: NodeConditionMismatchLevel.Critical,
      type: NodeConditionMismatchType.MissingColumn,
    };
  }

  // Numeric condition
  if (
    node.condition.type === ConditionType.Simple ||
    node.condition.type === ConditionType.Range
  ) {
    if (metaData.type === FieldType.Number) {
      // Condition can be converted to a metadata type
      return null;
    } else {
      // Numeric condition and nonnumeric metadata
      if (metaData.isDiscreteColumn) {
        return {
          level: NodeConditionMismatchLevel.Critical,
          type: NodeConditionMismatchType.NumericToDiscrete,
        };
      } else {
        return {
          level: NodeConditionMismatchLevel.Critical,
          type: NodeConditionMismatchType.Text,
        };
      }
    }
  }

  // Discrete condition
  if (node.condition.type === ConditionType.Discrete) {
    if (metaData.isDiscreteColumn) {
      // Condition can be converted to a metadata type

      const values = new Set(node.condition.values.map(({ value }) => value));
      const hasMissedValues = metaData.statistics?.textCategories?.some(
        ({ value }) => !values.has(String(value))
      );

      return !hasMissedValues
        ? null
        : {
            level: NodeConditionMismatchLevel.Warning,
            type: NodeConditionMismatchType.MissingDiscreteValues,
          };
    } else {
      if (metaData.type === FieldType.String) {
        // Discrete condition and text metadata
        return {
          level: NodeConditionMismatchLevel.Critical,
          type: NodeConditionMismatchType.Text,
        };
      }

      // Discrete text condition and numeric metadata
      return {
        level: NodeConditionMismatchLevel.Critical,
        type: NodeConditionMismatchType.DiscreteTextToNumeric,
      };
    }
  }

  if (node.condition.type === ConditionType.DesirabilityFunction) {
    if (metaData.type === FieldType.Number || metaData.isDiscreteColumn) {
      return null;
    }

    return {
      level: NodeConditionMismatchLevel.Critical,
      type: NodeConditionMismatchType.Text,
    };
  }

  assertUnreachable(node.condition, 'Unsupported condition type');
};

const getDateNodeTypeMismatch = (
  node: DecisionTreeDateNode,
  metaData: IColumnMetaInfo | undefined
): NodeConditionMismatch | null => {
  if (!node.condition) {
    return null;
  }

  if (!metaData) {
    // Column is missing
    return {
      level: NodeConditionMismatchLevel.Critical,
      type: NodeConditionMismatchType.MissingColumn,
    };
  }

  if (metaData.type === FieldType.String) {
    return {
      level: NodeConditionMismatchLevel.Critical,
      type: NodeConditionMismatchType.Text,
    };
  }

  if (
    node.condition.type === ConditionType.Simple ||
    node.condition.type === ConditionType.Range
  ) {
    if (metaData.type !== FieldType.Date) {
      return {
        level: NodeConditionMismatchLevel.Critical,
        type: NodeConditionMismatchType.NotDateColumn,
      };
    }

    return null;
  }

  assertUnreachable(node.condition, 'Unsupported condition type');
};

const getChartNodeTypeMismatch = (
  node: DecisionTreeChartNode,
  metaData: IColumnMetaInfo | undefined
): NodeConditionMismatch | null => {
  if (!node.columnId) {
    // Column not specified
    return null;
  }

  if (!metaData) {
    // Column is missing
    return {
      level: NodeConditionMismatchLevel.Critical,
      type: NodeConditionMismatchType.MissingColumn,
    };
  }

  if (metaData.type === FieldType.Number) {
    // Numeric metadata
    return null;
  }

  if (metaData.type === FieldType.String) {
    if (metaData.isDiscreteColumn) {
      // Discrete text metadata
      return null;
    } else {
      // Text metadata
      return {
        level: NodeConditionMismatchLevel.Critical,
        type: NodeConditionMismatchType.Text,
      };
    }
  }

  throw new Error('Unexpected field type');
};

const getStructureNodeTypeMismatch = (
  node: DecisionTreeStructSearchNode,
  metaData: IColumnMetaInfo | undefined
): NodeConditionMismatch | null => {
  if (!node.propertyId) {
    return null;
  }

  if (!metaData) {
    // Column is missing
    return {
      level: NodeConditionMismatchLevel.Critical,
      type: NodeConditionMismatchType.MissingColumn,
    };
  }

  if (metaData.type === FieldType.String) {
    return {
      level: NodeConditionMismatchLevel.Critical,
      type: NodeConditionMismatchType.Text,
    };
  }

  if (metaData.type !== FieldType.Structure) {
    return {
      level: NodeConditionMismatchLevel.Critical,
      type: NodeConditionMismatchType.NotStructureColumn,
    };
  }

  return null;
};

/**
 * Determines if there is a type mismatch between the node condition and the metadata.
 *
 * @param node - The decision tree node to check.
 * @param metaData - The metaData for given node.
 * @returns The type mismatch or `null` if no type mismatch is detected.
 */
export const getTypeMismatch = (
  node: DecisionTreeNode,
  metaData: IColumnMetaInfo | undefined
): NodeConditionMismatch | null => {
  if (isPropertyNode(node)) {
    return getPropertyNodeTypeMismatch(node, metaData);
  }

  if (isChartNode(node)) {
    return getChartNodeTypeMismatch(node, metaData);
  }

  if (isStructSearchNode(node)) {
    return getStructureNodeTypeMismatch(node, metaData);
  }

  if (isDateNode(node)) {
    return getDateNodeTypeMismatch(node, metaData);
  }

  if (
    isGroupNode(node) ||
    isOrNode(node) ||
    isAndNode(node) ||
    isAtLeastNNode(node) ||
    isAtMostXNode(node)
  ) {
    return null;
  }

  assertUnreachable(node);
};

export const getMismatchDescription = (mismatch: NodeConditionMismatch): string => {
  const type = mismatch.type;

  switch (type) {
    case NodeConditionMismatchType.MissingColumn:
      return 'The column is missing in the current dataset';
    case NodeConditionMismatchType.MissingDiscreteValues:
      return 'Not all discrete values of the condition are presented in the dataset';
    case NodeConditionMismatchType.Text:
      return 'Unable to apply condition to text column';
    case NodeConditionMismatchType.NumericToDiscrete:
      return 'Unable to apply a numeric condition to discrete column';
    case NodeConditionMismatchType.DiscreteTextToNumeric:
      return 'Unable to apply discrete condition to numeric column';
    case NodeConditionMismatchType.NotStructureColumn:
      return 'Unable to apply structure search condition to column not of structure type';
    case NodeConditionMismatchType.NotDateColumn:
      return 'Unable to apply date condition to column not of date type';
    default:
      assertUnreachable(type);
  }
};

/**
 * Converts a DecisionTreeNode object to a DiagramNodeData object used in a diagram node.
 *
 * @param node - The DecisionTreeNode object to convert.
 * @param position - The position of the diagram node.
 * @param metaData - The metaData for given node.
 * @returns The corresponding DiagramNodeData object.
 */
export const decisionTreeNodeToDiagramNodeData = (
  node: DecisionTreeNode,
  position: XYCoord,
  metaData?: IColumnMetaInfo
): DiagramNodeData => {
  let nodeName = '';

  if (isPropertyLikeNode(node)) {
    nodeName = node.propertyId;
  } else if (isChartNode(node)) {
    nodeName = node.columnId ? `Chart - ${node.columnId}` : 'Chart';
  } else if (isGroupNode(node)) {
    nodeName = 'Summary';
  }

  const data: DiagramNodeData = {
    key: node.id,
    group: node.group,
    isGroup: isGroupNode(node),
    text: nodeName,
    category: node.type,
    nodeN: isAtLeastNNode(node) || isAtMostXNode(node) ? node.nodeN : null,
    position: { x: position?.x || 0, y: position?.y || 0 },
    condition: isPropertyNode(node) || isDateNode(node) ? node.condition : null,
    structure: isStructSearchNode(node) ? node.structure : null,
    structSvg: isStructSearchNode(node) ? node.structSvg : null,
    portGroupingType: isPropertyLikeNode(node)
      ? node.portGroupingType || PortGroupingType.Regular
      : null,
    columnId: isChartNode(node) ? node.columnId : null,
    showFullDataset: isChartNode(node) ? Boolean(node.showFullDataset) : false,
    mismatch: getTypeMismatch(node, metaData),
  };

  return data;
};

/**
 * Converts a DiagramNodeData object to a DecisionTreeNode object used in a decision tree.
 *
 * @param nodeData - The DiagramNodeData object to convert.
 * @returns The corresponding DecisionTreeNode object.
 */
export const diagramNodeDataToDecisionTreeNode = (
  nodeData: DiagramNodeData
): DecisionTreeNode => {
  switch (nodeData.category) {
    case DTNodeType.Property:
      return {
        id: nodeData.key,
        group: nodeData.group,
        type: nodeData.category,
        propertyId: nodeData.text,
        inputArrows: [],
        outputArrows: {
          yes: [],
          no: [],
          missingValues: [],
        },
        condition: nodeData.condition,
        portGroupingType: nodeData.portGroupingType,
      };

    case DTNodeType.Date:
      return {
        id: nodeData.key,
        group: nodeData.group,
        type: nodeData.category,
        propertyId: nodeData.text,
        inputArrows: [],
        outputArrows: {
          yes: [],
          no: [],
          missingValues: [],
        },
        condition: nodeData.condition as DateCondition, // TODO create dateCondition field?
        portGroupingType: nodeData.portGroupingType,
      };

    case DTNodeType.StructureSearch:
      return {
        id: nodeData.key,
        group: nodeData.group,
        type: nodeData.category,
        propertyId: nodeData.text,
        structure: nodeData.structure,
        structSvg: nodeData.structSvg,
        portGroupingType: nodeData.portGroupingType,
        inputArrows: [],
        outputArrows: {
          yes: [],
          no: [],
          missingValues: [],
        },
      };

    case DTNodeType.ALN:
    case DTNodeType.AMX:
      return {
        id: nodeData.key,
        group: nodeData.group,
        type: nodeData.category,
        nodeN: nodeData.nodeN || 3,
        inputArrows: [],
        outputArrows: {
          combine: [],
        },
      };

    case DTNodeType.And:
    case DTNodeType.Or:
      return {
        id: nodeData.key,
        group: nodeData.group,
        type: nodeData.category,
        inputArrows: [],
        outputArrows: {
          combine: [],
        },
      };

    case DTNodeType.Chart:
      return {
        id: nodeData.key,
        group: nodeData.group,
        type: nodeData.category,
        columnId: nodeData.columnId,
        showFullDataset: nodeData.showFullDataset,
        inputArrows: [],
        outputArrows: {
          combine: [],
        },
      };

    case DTNodeType.Group:
      return {
        id: nodeData.key,
        type: nodeData.category,
        inputArrows: [],
        outputArrows: {},
      };
  }

  return assertUnreachable(nodeData.category);
};

/**
 * Converts a DecisionTreeArrow object to a DiagramArrowData object used in a diagram arrow.
 *
 * @param arrow - The DecisionTreeArrow object to convert.
 * @param fromPort - The port ID of the starting point of the arrow.
 * @returns The corresponding DiagramArrowData object.
 */
export const decisionTreeArrowToDiagramArrowData = (
  arrow: DecisionTreeArrow,
  fromPort?: DTNodeOutputPortType
): DiagramArrowData => ({
  key: arrow.id,
  from: arrow.from,
  fromPort: fromPort || '',
  to: arrow.to,
  toPort: 'input',
  group: arrow.group,
});

/**
 * Converts a DiagramArrowData object to a DecisionTreeArrow object used in a decision tree.
 *
 * @param arrowData - The DiagramArrowData object to convert.
 * @returns The corresponding DecisionTreeArrow object.
 */
export const diagramArrowDataToDecisionTreeArrow = (
  arrowData: DiagramArrowData
): DecisionTreeArrow => ({
  id: arrowData.key,
  from: arrowData.from,
  to: arrowData.to,
  group: arrowData.group,
});

/**
 * Converts a DecisionTree object to a DiagramState object.
 *
 * @param decisionTree - The DecisionTree object to be converted.
 * @param getMetaData - A function that retrieves metadata for a given property.
 * @returns The converted DiagramState object.
 */
export const decisionTreeToDiagramState = (
  decisionTree: IDecisionTreeNew,
  getMetaData: (columnId: ColumnId) => IColumnMetaInfo | undefined
): DiagramState => {
  const arrowPortMap = new Map<DTArrowId, DTNodeOutputPortType>();
  const nodes: DiagramNodeData[] = [];
  const arrows: DiagramArrowData[] = [];

  decisionTree.data.nodes.forEach((node) => {
    Object.entries(node.outputArrows || {}).forEach(([slotName, ids]) => {
      ids.forEach((id) => arrowPortMap.set(id, slotName as DTNodeOutputPortType));
    });

    let metaData;

    if (isPropertyLikeNode(node)) {
      metaData = getMetaData(node.propertyId);
    } else if (isChartNode(node) && node.columnId) {
      metaData = getMetaData(node.columnId);
    }

    nodes.push(
      decisionTreeNodeToDiagramNodeData(node, { x: node.x, y: node.y }, metaData)
    );
  });

  decisionTree.data.arrows.forEach((arrow) => {
    arrows.push(decisionTreeArrowToDiagramArrowData(arrow, arrowPortMap.get(arrow.id)));
  });

  return {
    id: decisionTree._id || uuid(),
    name: decisionTree.name,
    versionName: decisionTree.versionName,
    nodes,
    arrows,
    layout: decisionTree.data.layout,
    editableNodeId: null,
    skipsDiagramUpdate: false,
  };
};

/**
 * Cleans the data leaving only the necessary data to be sent to the server
 *
 * @param decisionTree - DecisionTree object to be sanitized
 */
export function sanitizeDecisionTree(decisionTree: IDecisionTreeNew): IDecisionTreeNew {
  return {
    _id: decisionTree._id,
    name: decisionTree.name,
    versionName: decisionTree.versionName,
    parentId: decisionTree.parentId,
    description: decisionTree.description,
    data: decisionTree.data,
  };
}

/**
 * Converts a DiagramState object to an IDecisionTree object.
 *
 * @param diagramState - The DiagramState object to convert.
 * @param decisionTree - An optional DecisionTree object to merge with the converted result.
 * @returns The corresponding IDecisionTree object.
 */
export function diagramStateToDecisionTree(
  diagramState: DiagramState,
  decisionTree?: IDecisionTreeNew
): IDecisionTreeNew {
  const nodes = new Map<DTNodeId, DecisionTreeNode & XYCoord>();
  const arrows = new Map<DTArrowId, DecisionTreeArrow>();

  diagramState.nodes.forEach((nodeData) => {
    const dtNode = diagramNodeDataToDecisionTreeNode(nodeData);

    nodes.set(dtNode.id, {
      ...dtNode,
      x: nodeData.position.x,
      y: nodeData.position.y,
    });
  });

  diagramState.arrows.forEach((arrowData) => {
    const dtArrow = diagramArrowDataToDecisionTreeArrow(arrowData);

    const from = nodes.get(dtArrow.from);
    const to = nodes.get(dtArrow.to);

    if (!from || !to || !(arrowData.fromPort in from.outputArrows)) return;

    // TODO: Rewrite without any
    (from.outputArrows as any)[arrowData.fromPort].push(dtArrow.id);
    to.inputArrows.push(dtArrow.id);

    arrows.set(dtArrow.id, dtArrow);
  });

  if (decisionTree) {
    return {
      _id: decisionTree._id,
      name: decisionTree.name,
      versionName: decisionTree.versionName,
      parentId: decisionTree.parentId,
      description: decisionTree.description,
      data: {
        layout: diagramState.layout,
        nodes: Array.from(nodes.values()),
        arrows: Array.from(arrows.values()),
      },
    };
  }

  return {
    _id: '',
    name: '',
    versionName: '',
    data: {
      layout: diagramState.layout,
      nodes: Array.from(nodes.values()),
      arrows: Array.from(arrows.values()),
    },
  };
}

/**
 * Retrieves the document coordinates to the diagram coordinates.
 *
 * @param diagram - The GoJS diagram instance.
 * @param coords - The XY coordinates to convert.
 * @returns The transformed XY coordinates in the diagram's coordinate system, or null if the diagram's container is not available.
 */
export const getDiagramCoordinates = (
  diagram: go.Diagram,
  coords: XYCoord
): XYCoord | null => {
  if (!diagram.div) return null;

  const bbox = diagram.div.getBoundingClientRect();

  const corner = {
    x: bbox.x,
    y: bbox.y,
  };
  const diff = {
    x: coords.x - corner.x,
    y: coords.y - corner.y,
  };

  return diagram.transformViewToDoc(new go.Point(diff.x, diff.y));
};

/**
 * Retrieves the diagram node port based on the provided diagram and document coordinates.
 *
 * @param diagram - The GoJS diagram instance.
 * @param coords - The coordinates of the point on the document to retrieve the port.
 * @returns The diagram node port object or null if no port is found.
 */
export const getPortByCoordinates = (
  diagram: go.Diagram,
  coords: XYCoord
): DiagramNodePort | DiagramArrowPort | null => {
  const diagramCoords = getDiagramCoordinates(diagram, coords);
  const point = new go.Point(diagramCoords?.x, diagramCoords?.y);

  if (!point) return null;

  const object = diagram.findObjectAt(point);

  if (!object || object.part?.containingGroup) {
    return null;
  }

  let panel = object.panel;
  let portId = object.portId;

  while (!portId && panel && object.part !== panel) {
    if (panel.portId) {
      portId = panel.portId;
      break;
    }

    panel = panel.panel;
  }

  const objectId = object.part?.data.key;

  if (!objectId) {
    return null;
  }

  if (portId && portId !== 'input') {
    return {
      type: 'nodePort',
      nodeId: objectId,
      portType: portId as DTNodeOutputPortType,
    };
  }

  if (object.part?.data.fromPort) {
    return { type: 'arrowPort', arrowId: objectId };
  }

  return null;
};

/**
 * Retrieves the diagram node based on the provided diagram and document coordinates.
 *
 * @param diagram - The GoJS diagram instance.
 * @param coords - The coordinates of the point on the document to retrieve the node.
 * @returns The diagram node object or null if node is not found.
 */
export const getNodeByCoordinates = (
  diagram: go.Diagram,
  coords: XYCoord
): DiagramNodeData | null => {
  const diagramCoords = getDiagramCoordinates(diagram, coords);
  const point = new go.Point(diagramCoords?.x, diagramCoords?.y);

  if (!point) return null;

  const object = diagram.findObjectAt(point);
  const nodeData = object?.part?.data as DiagramNodeData | undefined;

  if (
    !nodeData ||
    nodeData.group ||
    nodeData.isGroup ||
    !nodeData?.category || // is node // replace with type guard
    object?.part?.containingGroup
  ) {
    return null;
  }

  let panel = object?.panel;

  while (panel && panel.name !== 'container') {
    panel = panel.panel;
  }

  return panel?.name === 'container' ? nodeData : null;
};

/**
 * Applies incremental updates on the diagram state.
 *
 * @param diagramState - The current diagram state.
 * @param incrementalData - The data containing updates to be applied.
 * @param diagram - GoJS Diagram instance
 * @returns The updated diagram state after applying the updates.
 */
export const applyUpdatesOnDiagramState = (
  diagramState: DiagramState,
  incrementalData: go.IncrementalData,
  diagram: go.Diagram | null
): DiagramState => {
  const {
    insertedNodeKeys,
    modifiedNodeData,
    removedNodeKeys,
    insertedLinkKeys,
    modifiedLinkData,
    removedLinkKeys,
  } = incrementalData;
  const newState = structuredClone(diagramState);

  newState.skipsDiagramUpdate = true;
  const modifiedNodeMap = new Map<go.Key, DiagramNodeData>();
  const modifiedLinkMap = new Map<go.Key, DiagramArrowData>();

  let narr = newState.nodes;
  let larr = newState.arrows;

  if (modifiedNodeData) {
    modifiedNodeData.forEach((nd: go.ObjectData) => {
      const nodeData = nd as DiagramNodeData;

      modifiedNodeMap.set(nodeData.key, nodeData);

      const idx = narr.findIndex((node: DiagramNodeData) => node.key === nodeData.key);

      if (idx === -1) return;
      narr[idx] = nodeData;
    });
  }

  if (insertedNodeKeys) {
    insertedNodeKeys.forEach((key: go.Key, _, array: go.Key[]) => {
      const nd = modifiedNodeMap.get(key);
      const idx = narr.findIndex((node: DiagramNodeData) => node.key === key);

      if (!nd || idx !== -1) {
        if (diagram?.model.modelData.highlightedPort) {
          //if new node is inserted to an arrow then we cut the arrow into two
          //with the new node in the middle
          const oldArrow = larr.find(
            (node: DiagramArrowData) =>
              node.key === diagram?.model.modelData.highlightedPort.arrowId
          );

          if (!oldArrow) {
            return;
          }
          const arrow: DiagramArrowData = {
            key: uuid(),
            from: narr[idx].key,
            fromPort: narr[idx].category === DTNodeType.Property ? 'yes' : 'combine',
            to: oldArrow.to,
            toPort: 'input',
          };

          oldArrow.to = narr[idx].key;
          larr.push(arrow);
          //update diagram on custom change
          newState.skipsDiagramUpdate = false;
        }

        return;
      }
      nd.position.x += 20;
      nd.position.y += 20;
      narr.push(nd as DiagramNodeData);

      if (diagram) {
        // check if need to create arrow
        const modelData = diagram.model.modelData as DiagramData | undefined;
        const selectedNode = modelData?.selectedNode;

        if (selectedNode && array.length === 1) {
          const arrow: DiagramArrowData = {
            key: uuid(),
            from: selectedNode.key,
            fromPort: selectedNode.category === DTNodeType.Property ? 'yes' : 'combine',
            to: nd.key,
            toPort: 'input',
          };

          larr.push(arrow);

          // update diagram on custom change
          newState.skipsDiagramUpdate = false;
          updateModelData(diagram, { selectedNode: null });
        }
      }
    });
  }

  if (removedNodeKeys) {
    narr = narr.filter((nd: go.ObjectData) => {
      return !removedNodeKeys.includes(nd.key);
    });
  }

  if (modifiedLinkData) {
    modifiedLinkData.forEach((ld: go.ObjectData) => {
      modifiedLinkMap.set(ld.key, ld as DiagramArrowData);

      const idx = larr.findIndex((link: DiagramArrowData) => link.key === ld.key);

      if (idx === -1) return;
      larr[idx] = ld as DiagramArrowData;
    });
  }

  if (insertedLinkKeys) {
    insertedLinkKeys.forEach((key: go.Key) => {
      const ld = modifiedLinkMap.get(key);
      const idx = larr.findIndex((node: DiagramArrowData) => node.key === key);

      if (!ld || idx !== -1) return;
      larr.push(ld as DiagramArrowData);
    });
  }

  if (removedLinkKeys) {
    larr = larr.filter((ld: go.ObjectData) => {
      return !removedLinkKeys.includes(ld.key);
    });
  }

  newState.nodes = narr;
  newState.arrows = larr;

  // GoJS model already knows about these updates

  return newState;
};

/**
 * Remove the node arrow from missing port or moves it to available true/false port
 * @param arrows - diagram arrows
 * @param nodeId - id of the selected node
 * @param portGroupingType - grouping type of the selected node
 * @returns arrows - processed arrows
 */
const applyArrowsGrouping = (
  arrows: DiagramArrowData[],
  nodeId: DTNodeId,
  portGroupingType: PortGroupingType | null
): DiagramArrowData[] => {
  if (!portGroupingType || portGroupingType === PortGroupingType.Regular) {
    return arrows;
  }

  const missingValueArrows = arrows.filter(
    (arrow) => arrow.from === nodeId && arrow.fromPort === 'missingValues'
  );

  if (missingValueArrows.length === 0) return arrows;

  let portId: DTNodeOutputPortType | null = null;

  switch (portGroupingType) {
    case PortGroupingType.TrueMissing:
      portId = 'yes';
      break;

    case PortGroupingType.FalseMissing:
      portId = 'no';
      break;

    case PortGroupingType.HideMissing:
      break;

    default:
      assertUnreachable(portGroupingType, 'Unexpected port grouping type');
  }

  if (portId !== null) {
    const arrowsNodes = new Set();

    for (const arrow of arrows) {
      if (arrow.from !== nodeId || arrow.fromPort !== portId) continue;

      arrowsNodes.add(arrow.to);
    }

    for (const arrow of missingValueArrows) {
      if (arrowsNodes.has(arrow.to)) continue;
      arrowsNodes.add(arrow.to);

      arrow.fromPort = portId;
    }

    return arrows;
  }

  return arrows.filter(
    (arrow) => !(arrow.from === nodeId && arrow.fromPort === 'missingValues')
  );
};

/**
 * Remove the node arrow from the lost ports of the replaced node
 * @param arrows - diagram arrows
 * @param nodeId - id of the replaced node
 * @param newNodeType - type of the replaced node
 * @returns arrows - processed arrows
 */
const applyArrowsNodeReplacing = (
  arrows: DiagramArrowData[],
  nodeId: DTNodeId,
  newNodeType: DTNodeType
): DiagramArrowData[] => {
  const newArrows: DiagramArrowData[] = [];

  arrows.forEach((arrow) => {
    if (arrow.from !== nodeId) {
      newArrows.push({ ...arrow });

      return;
    }

    switch (newNodeType) {
      case DTNodeType.ALN:
      case DTNodeType.AMX:
      case DTNodeType.And:
      case DTNodeType.Chart:
      case DTNodeType.Or:
        newArrows.push({ ...arrow, fromPort: 'combine' });
        break;

      case DTNodeType.Property:
      case DTNodeType.StructureSearch:
      case DTNodeType.Date:
        newArrows.push({ ...arrow, fromPort: 'yes' });
        break;

      case DTNodeType.Group:
        break;

      default:
        assertUnreachable(newNodeType, 'Unexpected port grouping type');
    }
  });

  return newArrows;
};

/**
 * Applies new diagram node data on the diagram state.
 *
 * @param nodeData - The data containing updates to be applied.
 * @param diagramState - The current diagram state.
 * @param port - The diagram node port to which the provided node should be connected.
 * @returns The updated diagram state after applying the updates.
 */
export const applyNodeUpdatesOnDiagramState = (
  diagramState: DiagramState,
  nodeData: DiagramNodeData,
  port?: DiagramNodePort | DiagramArrowPort | null
) => {
  const newState = structuredClone(diagramState);
  const { nodes } = newState;

  let newArrows = [...newState.arrows];

  // Find the index of the existing node with the same id
  const nodeIdx = nodes.findIndex((item: DiagramNodeData) => item.key === nodeData.key);

  if (nodeIdx !== -1) {
    if (nodes[nodeIdx].category !== nodeData.category) {
      // Update arrows in case of replacing nodes
      newArrows = applyArrowsNodeReplacing(newArrows, nodeData.key, nodeData.category);
    }

    // Update existing node data
    nodes[nodeIdx] = {
      ...nodes[nodeIdx],
      ...nodeData,
      position: nodes[nodeIdx].position,
    };
  } else {
    // Add new node
    nodes.push(nodeData);
  }

  // Find the index of the parent node we want to connect to
  const parentNodeIdx = nodes.findIndex(
    (item: DiagramNodeData) => port?.type === 'nodePort' && item.key === port.nodeId
  );

  if (port && parentNodeIdx !== -1 && port.type === 'nodePort') {
    // Create a new arrow
    newArrows.push(
      decisionTreeArrowToDiagramArrowData(
        { id: uuid(), from: port.nodeId, to: nodeData.key },
        port.portType
      )
    );
  }

  newArrows = applyArrowsGrouping(newArrows, nodeData.key, nodeData.portGroupingType);

  return {
    ...diagramState,
    nodes,
    arrows: newArrows,
    skipsDiagramUpdate: false,
  };
};

/**
 * Retrieves the filtered groups from the provided decision tree node groups.
 *
 * @param nodeGroups - The decision tree node groups.
 * @returns An array of filtered groups.
 */
export const getFilteredGroups = (nodeGroups: DecisionTreeNodeGroups): string[] => {
  const outputGroups = new Set();

  if (isPropertyNodeGroups(nodeGroups)) {
    Object.keys(nodeGroups.outputGroups.yes).forEach((group) => outputGroups.add(group));
    Object.keys(nodeGroups.outputGroups.missingValues).forEach((group) =>
      outputGroups.add(group)
    );
  } else if (isAndNodeGroups(nodeGroups) || isOrNodeGroups(nodeGroups)) {
    Object.keys(nodeGroups.outputGroups.combine).forEach((group) =>
      outputGroups.add(group)
    );
  }

  return Object.keys(nodeGroups.inputGroups).filter((group) => !outputGroups.has(group));
};

/**
 * Creates a map of group names to their corresponding colors.
 *
 * @param options - An array of DTGroupOption objects representing group options.
 * @returns A record object mapping group names to colors.
 */
export const groupOptionsToColorsMap = (
  options: DTGroupOption[]
): Map<DTGroupOption['name'], DTGroupOption['color']> => {
  return options.reduce((acc, group) => {
    acc.set(group.name, group.color);

    return acc;
  }, {} as Map<DTGroupOption['name'], DTGroupOption['color']>);
};

/**
 * Find the empty position for a new node in a diagram based on the existing nodes.
 *
 * @param nodes - An array of existing nodes in the diagram.
 * @returns An object representing the empty position in DiagramNode position format.
 */
export const findEmptyPosition = (
  nodes: DiagramNodeData[]
): DiagramNodeData['position'] => {
  let maxX: number;
  let maxY: number;

  if (nodes.length === 0) {
    maxX = 0;
    maxY = 0;
  } else {
    maxX = nodes[0].position.x;
    maxY = nodes[0].position.y;

    nodes.forEach(({ position }) => {
      const { x, y } = position;

      if (y > maxY) {
        maxY = y;
        maxX = x;
      }

      if (y === maxY && x > maxX) {
        maxX = x;
      }
    });

    maxX += NODE_WIDTH + NODES_GAP;
  }

  return {
    x: maxX,
    y: maxY,
  };
};

/**
 * Generates an array of histogram colors based on the provided groups.
 *
 * @param groupOptions - Optional. Array of group options.
 * @returns An array of Color objects representing histogram colors.
 */
const groupOptionsToHistogramColors = (groupOptions?: DTGroupOption[]): GroupColor[] => {
  const hasNull = groupOptions?.some(({ name }) => name === null);
  const colors: GroupColor[] = [];

  if (groupOptions) {
    groupOptions.forEach(({ name, color }) => {
      colors.push({
        group: name,
        color,
      });
    });
  }

  if (!hasNull) {
    colors.push({ group: null, color: HISTOGRAM_BAR });
  }

  return colors;
};

/**
 * Creates a group mapping with the corresponding index in the colors array.
 *
 * @param colors - An array of colors.
 * @returns A map that maps group names to their corresponding index.
 */
const colorsToIndexMap = (colors: GroupColor[]): Map<DTGroupOption['name'], number> => {
  return colors.reduce((acc, { group }, index) => {
    acc.set(group, index);

    return acc;
  }, new Map() as Map<DTGroupOption['name'], number>);
};

/**
 * Converts an array of rows to discrete histogram data.
 *
 * @param params.rows - An array of row values.
 * @param params.groupOptions - Optional. Group options to define colors for histogram bars.
 * @returns Discrete histogram data.
 */
export const rowsToDiscreteHistogramData = (params: {
  rows: DecisionTreeCellValuesWithGroups;
  discreteValues?: DiscreteValue[];
  groupOptions?: DTGroupOption[];
}): DiscreteHistogramData => {
  const { rows, discreteValues, groupOptions } = params;
  const data: DiscreteHistogramData = {
    type: 'discrete',
    bars: [],
    colors: groupOptionsToHistogramColors(groupOptions),
  };
  const groupsIndexMap = colorsToIndexMap(data.colors);

  // Fill colors and groups indexes to keep ordering for hisogram slices
  if (groupOptions) {
    data.colors = [];
    groupOptions.forEach(({ name, color }, index) => {
      groupsIndexMap.set(name, index);
      data.colors[index] = {
        group: name,
        color,
      };
    });
  }

  const bars: Map<string, DiscreteBar> = new Map(
    discreteValues ? discreteValues.map(({ value }) => [value, { x: value, y: [0] }]) : []
  );

  rows.values.forEach((value, index) => {
    const group = rows.groups[index];

    if (value === null) return;

    const sanitized = String(value);

    if (!bars.has(sanitized)) {
      bars.set(sanitized, {
        x: sanitized,
        y: [0],
      });
    }

    const groupIndex = groupsIndexMap.get(group) || 0;

    bars.get(sanitized)!.y[groupIndex] = (bars.get(sanitized)!.y[groupIndex] || 0) + 1;
  });
  data.bars = [...bars.values()];

  return data;
};

/**
 * Converts column metadata to a bars for a numeric histogram.
 *
 * @param metaData - Column metadata containing information about the column.
 * @returns Array of numeric bars representing the histogram bars.
 */
const metaDataToNumericBars = (metaData: IColumnMetaInfo): NumericBar[] => {
  return metaData.histogramBars.map(({ x0, x }) => ({ x: [x0, x], y: [] }));
};

/**
 * Converts an array of rows to numeric histogram data.
 *
 * @param params.rows - An array of row values.
 * @param params.metaData - The metadata of the column.
 * @param params.groupOptions - Optional. Group options to define colors for histogram bars.
 * @returns Numeric histogram data.
 */
export const rowsToNumericHistogramData = (params: {
  rows: DecisionTreeCellValuesWithGroups;
  metaData: IColumnMetaInfo;
  groupOptions?: DTGroupOption[];
}): NumericHistogramData | null => {
  const { rows, metaData, groupOptions } = params;

  if (metaData.type !== FieldType.Number) return null;

  const data: NumericHistogramData = {
    type: 'numeric',
    bars: metaDataToNumericBars(metaData),
    colors: groupOptionsToHistogramColors(groupOptions),
  };
  const groupsIndexMap = colorsToIndexMap(data.colors);

  rows.values.forEach((value, index) => {
    const group = rows.groups[index];

    if (typeof value !== 'number') return;

    const barIndex = data.bars.findIndex(({ x }) => {
      return x[0] <= value && value <= x[1];
    });

    if (barIndex === -1) return;

    const groupIndex = groupsIndexMap.get(group) || 0;

    data.bars[barIndex].y[groupIndex] = (data.bars[barIndex].y[groupIndex] || 0) + 1;
  });

  return data;
};

/**
 * Converts an array of rows to date histogram data.
 *
 * @param params.rows - An array of row values.
 * @param params.metaData - The metadata of the column.
 * @param params.groupOptions - Optional. Group options to define colors for histogram bars.
 * @returns Date histogram data.
 */
export const rowsToDateHistogramData = (params: {
  rows: DecisionTreeCellValuesWithGroups;
  metaData: IColumnMetaInfo;
  groupOptions?: DTGroupOption[];
}): DateHistogramData | null => {
  const { rows, metaData, groupOptions } = params;

  if (metaData.type !== FieldType.Date) {
    return null;
  }

  const data: DateHistogramData = {
    type: 'date',
    bars: metaDataToNumericBars(metaData),
    colors: groupOptionsToHistogramColors(groupOptions),
  };

  const groupsIndexMap = colorsToIndexMap(data.colors);

  rows.values.forEach((value, index) => {
    const group = rows.groups[index];

    if (!dayjs.isDayjs(value)) {
      return;
    }

    const barIndex = data.bars.findIndex(({ x }, i) => {
      const timestamp = value.valueOf();
      const startCond = x[0] <= timestamp;
      const endCond = i === data.bars.length - 1 ? timestamp <= x[1] : timestamp < x[1];

      return startCond && endCond;
    });

    if (barIndex === -1) return;

    const groupIndex = groupsIndexMap.get(group) || 0;

    data.bars[barIndex].y[groupIndex] = (data.bars[barIndex].y[groupIndex] || 0) + 1;
  });

  return data;
};

/**
 * Create an empty property-like node from the column metadata
 *
 * @param propertyId - Column identifier
 * @param metaData - Optional. The metadata of the column.
 * @param defaultPortGrouping - Optional. Default port grouping mode.
 * @returns Decision tree node
 */
export const createDecisionTreeNode = (
  propertyId: ColumnId,
  metaData?: IColumnMetaInfo,
  defaultPortGrouping?: PortGroupingType | null
) => {
  if (metaData?.type === FieldType.Structure) {
    const structNode: DecisionTreeStructSearchNode = {
      id: uuid(),
      type: DTNodeType.StructureSearch,
      propertyId,
      structure: null,
      structSvg: null,
      portGroupingType:
        defaultPortGrouping !== undefined
          ? defaultPortGrouping
          : PortGroupingType.Regular,
      inputArrows: [],
      outputArrows: {
        yes: [],
        no: [],
        missingValues: [],
      },
    };

    return structNode;
  }

  if (metaData?.type === FieldType.Date) {
    const dateNode: DecisionTreeDateNode = {
      id: uuid(),
      type: DTNodeType.Date,
      propertyId,
      condition: metaData ? getDefaultDateConditions(metaData) : null,
      portGroupingType:
        defaultPortGrouping !== undefined
          ? defaultPortGrouping
          : PortGroupingType.Regular,
      inputArrows: [],
      outputArrows: {
        yes: [],
        no: [],
        missingValues: [],
      },
    };

    return dateNode;
  }

  const dtNode: DecisionTreePropertyNode = {
    id: uuid(),
    propertyId,
    condition: metaData ? getDefaultConditionSettings(metaData) : null,
    type: DTNodeType.Property,
    inputArrows: [],
    outputArrows: {
      yes: [],
      no: [],
      missingValues: [],
    },
    portGroupingType:
      defaultPortGrouping !== undefined ? defaultPortGrouping : PortGroupingType.Regular,
  };

  return dtNode;
};
